<template lang="pug">
  .calendar-month
    .calendar-month-header
      .calendar-month-pagination
        .first-month(@click="jumpToFirstMonth")
          span {{ "<<" }}
        .prev-month(@click="setPrevMonth")
          span {{ "<" }}
        .current-month
          span {{ currentMonthText }}
        .next-month(@click="setNextMonth")
          span {{ ">" }}
        .last-month(@click="jumpToLastMonth")
          span {{ ">>" }}
    .days-of-week
      .day(v-for="day in daysOfWeek")
        span {{ $t(`plan_management.modal.jalan_sale_settings.days_of_weeks.${day}`) }}
    .days-of-month
      template(v-for="day in daysRange")
        .day-cell(
          :class="buildDayClassObject(day)"
          @click="handleDayClick(day)"
        )
          span {{ getMonthDay(day) }}
          .plan-day(v-if="isIncludedInPeriod(day)")
          .special-day(
            v-if="periodByDay[day]"
            :class="buildSpecialPeriodDayClassObject(day)"
          )
            .lane(:class="[periodByDay[day].laneColor, buildLaneClassObject(day)]")
              .name(
                v-if="isFirstSpecialPeriodDay(day)"
              )
                span {{ periodByDay[day].name }}
</template>

<script>
  import { DAYS_OF_WEEK } from "./../../../constants.js"
  import { transform, find } from "lodash-es"

  export default {
    props: {
      period: {
        type: Array,
        default: () => new Array()
      },
      specialPeriods: {
        type: Array,
        default: () => new Array()
      },
      selectedPeriodId: {
        type: Number
      }
    },

    data() {
      return {
        currentDate: new Date(),
        daysOfWeek: DAYS_OF_WEEK
      }
    },

    methods: {
      handleDayClick(date) {
        if (this.periodByDay[date]) {
          this.$emit("select-special-period", this.periodByDay[date])
        } else {
          this.$emit("add-special-period", date)
        }
      },

      buildSpecialPeriodDayClassObject(day) {
        return {
          first: this.isFirstSpecialPeriodDay(day),
          last: this.isLastSpecialPeriodDay(day),
          selected: this.isSelected(day)
        }
      },

      buildLaneClassObject(day) {
        return {
          sunday: this.isSunday(day),
          saturday: this.isSaturday(day),
          first: this.isFirstSpecialPeriodDay(day),
          last: this.isLastSpecialPeriodDay(day)
        }
      },

      buildDayClassObject(date) {
        return {
          "not-current-month": this.isNotCurrentMonth(date),
          sunday: this.isSunday(date),
          saturday: this.isSaturday(date)
        }
      },

      isSelected(day) {
        return this.periodByDay[day].id === this.selectedPeriodId
      },

      isIncludedInPeriod(date) {
        return this.period[0] <= date && date <= this.period[1]
      },

      isIncludedInSpecialPeriodRange(period, date) {
        return period.start_date <= date && date <= period.end_date
      },

      isNotCurrentMonth(date) {
        return date.getMonth() !== this.currentMonth
      },

      isSunday(date) {
        return date.getDay() === 0
      },

      isSaturday(date) {
        return date.getDay() === 6
      },

      isFirstSpecialPeriodDay(date) {
        return this.periodByDay[date].start_date?.getDate() === date.getDate()
      },

      isLastSpecialPeriodDay(date) {
        return this.periodByDay[date].end_date?.getDate() === date.getDate()
      },

      setPrevMonth() {
        this.currentDate = new Date(this.currentYear, this.currentMonth - 1)
      },

      jumpToFirstMonth() {
        this.currentDate = this.period[0]
      },

      setNextMonth() {
        this.currentDate = new Date(this.currentYear, this.currentMonth + 1)
      },

      jumpToLastMonth() {
        this.currentDate = this.period[1]
      },

      getMonthDay(date) {
        return date.toLocaleDateString(undefined, { day: "numeric" })
      },

      findPeriodByDay(day) {
        return find(this.specialPeriods, period => this.isIncludedInSpecialPeriodRange(period, day))
      }
    },

    computed: {
      periodByDay({ daysRange }) {
        return transform(
          daysRange,
          (obj, day) => {
            const period = this.findPeriodByDay(day)
            if (period) {
              obj[day] = period
            }
          },
          {}
        )
      },

      currentYear() {
        return this.currentDate.getFullYear()
      },

      currentMonth() {
        return this.currentDate.getMonth()
      },

      currentMonthText() {
        return `${this.currentYear} ${this.currentDate.toLocaleDateString(undefined, { month: "2-digit" })}`
      },

      currentMonthStartDate({ currentYear, currentMonth }) {
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1)
        const weekStartDate = new Date(firstDayOfMonth)
        weekStartDate.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay())
        return weekStartDate
      },

      currentMonthLastDate({ currentYear, currentMonth }) {
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)
        const weekEndDate = new Date(lastDayOfMonth)
        weekEndDate.setDate(lastDayOfMonth.getDate() + (6 - lastDayOfMonth.getDay()))
        return weekEndDate
      },

      daysRange({ currentMonthStartDate, currentMonthLastDate }) {
        const daysRange = []
        let rangeDay = new Date(currentMonthStartDate)
        while (rangeDay <= currentMonthLastDate) {
          daysRange.push(new Date(rangeDay))
          rangeDay.setDate(rangeDay.getDate() + 1)
        }

        return daysRange
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .calendar-month
    .calendar-month-header
      margin: 10px 200px
      .calendar-month-pagination
        display: flex
        justify-content: space-between
        color: $default-gray

        .first-month,
        .prev-month,
        .next-month,
        .last-month
          cursor: pointer

        .current-month
          width: 200px
          font-weight: bold
          text-align: center

    .days-of-week
      background-color: $default-gray-light
      font-weight: bold
      color: $default-gray
      display: grid
      grid-template-columns: repeat(7, 1fr)

      .day
        text-align: center

    .days-of-month
      grid-gap: 1px
      background-color: $default-gray-light
      display: grid
      grid-template-columns: repeat(7, 1fr)
      border: 1px solid $default-gray-light

      .day-cell
        background-color: $default-white
        color: $default-gray
        padding-top: 5px
        text-align: center
        height: 98px

        .special-day,
        .plan-day
          height: 20px
          margin-top: 5px
          margin-right: -1px

        .plan-day
          background-color: $default-orange

        .special-day
          border-top: 1px solid $default-black
          border-bottom: 1px solid $default-black
          background-color: $default-white

          &.first
            border-left: 1px solid $default-black
            &.selected
              border-left-style: dashed

          &.last
            border-right: 1px solid $default-black
            &.selected
              border-right-style: dashed

          &.selected
            border-top-style: dashed
            border-bottom-style: dashed

          .lane
            margin: 1px -1px
            height: 16px

            .name
              padding-left: 3px
              color: white
              font-size: 0.7rem
              width: 80px
              text-align: left
              /*white-space: nowrap*/
              /*overflow: hidden*/
              /*text-overflow: ellipsis*/

              span


            &.first
              position: relative
              margin-left: 1px !important
            &.last
              margin-right: 1px !important
            &.sunday
              margin-left: 0px
            &.saturday
              margin-right: 0px

            &.black
              background-color: $default-black
            &.red
              background-color: $default-red
            &.blue
              background-color: $default-blue-dark
            &.yellow
              background-color: $default-yellow-light
            &.pink
              background-color: $default-red-medium
            &.green
              background-color: $default-green

        &.not-current-month
          span
            opacity: 0.5
        &.sunday
          color: $default-red
        &.saturday
          color: $default-blue-dark
</style>
